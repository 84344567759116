import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLakeRink = styled.div`
	background-image: url(/img/FLR_AboutUs_Img.jpg);
	background-position: left top;
	background-size: auto 100%;
	background-repeat: no-repeat;
	.text-container {
		padding: 4rem 5rem 4rem 3rem;
		width: 50%;
		margin-left: auto;
		background-color: ${(p) => p.theme.color.darkBlue};
		h1 {
			color: ${(p) => p.theme.color.red};
			font-size: 1.5rem;
			line-height: 2rem;
			@media all and (max-width: 1599px) {
				padding-right: 15rem;
			}
			@media all and (max-width: 1400px) {
				padding-right: 10rem;
			}
			@media all and (max-width: 1300px) {
				padding-right: 5rem;
			}
			@media all and (max-width: 1200px) {
				padding-right: 0rem;
			}
		}
		p {
			color: #fff;
			margin: 1rem 0 2rem;
		}
	}
	@media all and (min-width: 1200px) {
		.text-container {
			width: 60%;
			padding: 4rem 4rem;
			display: flex;
			justify-content: center;
			flex-direction: column;
			a {
				width: 100px;
			}
		}
	}
	@media all and (min-width: 1400px) {
		.text-container p {
			max-width: 550px;
		}
	}
	@media all and (min-width: 1500px) {
		background-size: 46% auto;
	}
	@media all and (min-width: 1600px) {
		background-size: 53% auto;
		background-position: -10% center;
		.text-container {
			padding: 6rem 4rem;
			p {
				max-width: 700px;
			}
		}
		h1 {
			font-size: 2rem;
			line-height: 2.5rem;
			width: 600px;
		}
		p {
			font-size: 1.3rem !important;
			line-height: 1.5rem;
		}
		a {
			font-size: 1.3rem !important;
			line-height: 1.5rem;
			width: 117px !important;
		}
	}
	@media all and (min-width: 1800px) {
		.text-container {
			padding: 7.5rem 4rem;
		}
	}
	@media all and (max-width: 1000px) {
		background-size: 100%;
		background-position: center 130%;
		padding-bottom: 18rem;
		.text-container {
			width: 100%;
			padding: 6rem;
		}
		h1 {
			width: 80%;
		}
	}
	@media all and (max-width: 768px) {
		background-position: center 120%;
		.text-container {
			padding: 4rem 6rem;
		}
		h1 {
			width: 100%;
		}
	}
	@media all and (max-width: 668px) {
		background-position: center bottom;
	}
	@media all and (max-width: 550px) {
		padding-bottom: 16rem;
	}
	@media all and (max-width: 500px) {
		padding-bottom: 16rem;
		.text-container {
			padding: 3rem 4rem 4rem;
		}
	}
	@media all and (max-width: 480px) {
		padding-bottom: 14rem;
	}
	@media all and (max-width: 400px) {
		padding-bottom: 12rem;
		.text-container {
			padding: 2rem 2rem 3rem 3rem;
		}
	}
	@media all and (max-width: 350px) {
		padding-bottom: 10rem;
	}
`;

const LakeRink = ({ data }) => {
	return (
		<StyledLakeRink>
			<div className="text-container">
				<h1>{data.lakeHeading}</h1>
				<p>{data.lakeCopy}</p>
				<Link className="redButton" to="/about">
					ABOUT US
				</Link>
			</div>
		</StyledLakeRink>
	);
};

export default LakeRink;
