import React from 'react';
import styled from 'styled-components';

const StyledProcess = styled.div`
	padding: 3rem 5rem 4rem 4rem;
	background-image: url(/img/FLR_About_Light-Ice.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	.left {
		width: 40%;
		margin-right: auto;
		display: flex;
		align-items: center;
	}
	.right {
		width: 53%;
		min-width: 400px;
		padding-right: 2rem;
		display: flex;
		align-items: center;
		h2 {
			color: ${(p) => p.theme.color.red};
			margin-bottom: 1rem;
		}
		h3 {
			font-size: 1.2rem;
			font-weight: 700;
			margin-top: 1rem;
		}
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.left img {
			width: 100%;
		}
	}
	@media all and (min-width: 1600px) {
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		h3 {
			font-size: 1.6rem !important;
			line-height: 2rem;
		}
		p {
			font-size: 1.3rem;
			line-height: 1.5rem;
		}
	}
	@media all and (min-width: 1300px) {
		padding: 3rem 8rem 4rem;
		.left {
			width: 38%;
			margin-left: auto;
			max-width: 500px;
		}
		.right {
			width: 45%;
			max-width: 600px;
			margin-right: auto;
		}
	}
	@media all and (max-width: 1000px) {
		padding: 3rem 3rem 4rem 1rem;
		.right {
			width: 55%;
		}
	}
	@media all and (max-width: 768px) {
		flex-direction: column-reverse;
		padding: 4rem;
		.left {
			width: 70%;
			min-width: 300px;
			margin: 2rem auto 0;
		}
		.right {
			width: 100%;
			min-width: auto;
			padding-right: 0;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem 4rem;
		.left {
			min-width: auto;
			width: 90%;
		}
	}
	@media all and (max-width: 400px) {
		padding: 3rem;
	}
`;

const Process = ({ data }) => {
	return (
		<StyledProcess>
			<div className="left">
				<img src="/img/FLR_Puck.png" alt="first line rinks puck" />
			</div>
			<div className="right">
				<div className="text-container">
					<h2>{data.processHeading}</h2>
					{data.process.map((item, index) => (
						<div key={index}>
							<h3>{item.title}</h3>
							<p>{item.copy}</p>
						</div>
					))}
				</div>
			</div>
		</StyledProcess>
	);
};

export default Process;
