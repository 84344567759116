import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledMasthead = styled.div`
	padding: 6rem 6rem 7rem 15%;
	background-image: url(/img/FLR_Home_Masthead_2.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.mainHeading {
		width: 250px;
		font-family: ${(props) => props.theme.fonts.heading};
		font-size: 2.5rem;
		line-height: 2.8rem;
		color: #fff;
	}
	h2 {
		color: #fff;
		margin: 1rem 0 2rem;
	}
	@media all and (min-width: 1100px) {
		padding: 0 6rem 0 15%;
		/* 					Height of nav */
		height: calc(100vh - 6.2rem);
		display: flex;
		align-items: center;
		.mainHeading {
			font-size: 4rem;
			line-height: 4.5rem;
			width: 400px;
		}
		h2 {
			margin: 2rem 0 3rem;
			font-size: 2rem;
		}
	}
	@media all and (min-width: 1600px) {
		/* 					Height of nav */
		height: calc(100vh - 8.95rem);
		.mainHeading {
			font-size: 5rem;
			line-height: 5.5rem;
			width: 500px;
		}
		h2 {
			margin: 3rem 0 4rem;
			font-size: 2.5rem;
		}
		a {
			font-size: 1.8rem;
			padding: 0.5rem 1.5rem !important;
		}
	}
	@media all and (max-width: 1000px) {
		padding: 6rem;
	}
	@media all and (max-width: 776px) {
		background-size: 120% auto;
		background-position: -20px center;
	}
	@media all and (max-width: 668px) {
		background-image: url(/img/FLR_Home_Masthead_2.jpg);
		background-position: center 70%;
		background-size: cover;
		padding: 3rem 4rem 20rem;
		display: flex;
		justify-content: center;
	}
	@media all and (max-width: 400px) {
		padding: 3rem 4rem 18rem;
		background-position: center bottom;
	}
	@media all and (max-width: 350px) {
		padding: 3rem 4rem 15rem;
	}
`;

const Masthead = ({ data }) => {
	return (
		<StyledMasthead>
			<div className="text-container">
				<p className="mainHeading">{data.mastHeading}</p>
				<h2>{data.mastSubheading}</h2>
				<Link className="redButton" to="/our-rinks">
					OUR RINKS
				</Link>
			</div>
		</StyledMasthead>
	);
};

export default Masthead;
