import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import HomeTemplate from '../templates/index-template';
import SEO from '../components/seo';

export const HomeGalleryQuery = graphql`
	{
		projects: allProjectsJson(filter: { featured: { eq: true } }, limit: 1) {
			edges {
				node {
					title
					images
					fields {
						slug
					}
					gallery {
						excerpt
					}
				}
			}
		}
	}
`;

const copy = {
	mastHeading: "CANADA'S GAME IN YOUR OWN BACKYARD",
	// mastSubheading: 'STARTING AT $5,500*',
	lakeHeading:
		'PROFESSIONAL QUALITY ICE RINK SYSTEMS DELIVERED TO YOUR BACKYARD OR BUSINESS.',
	lakeCopy: `First Line Rinks provides families and corporate businesses with the opportunity to have a portable ice rink system in the location of their choice. Whether it’s a modest backyard ice rink, a synthetic system in your basement, or a large custom pro rink that's 200 feet long, you can trust First Line Rinks to deliver the best service and highest quality in the industry.`,
	processHeading: 'THE FIRST LINE PROCESS:',
	process: [
		{
			title: 'Site Visit',
			copy:
				'During our first site visit, we’ll assess the desired location for your new rink and work with you to select the features you’d like your rink to have.',
		},
		{
			title: 'Rink Plan Development',
			copy:
				'Our team puts together a simple and detailed outline of the project, process and timeline for your review.',
		},
		{
			title: 'Production',
			copy:
				'In as little as two weeks, we can have your rink completed and ready for your enjoyment.',
		},
		{
			title: 'Installation',
			copy:
				'We pride ourselves on a smooth installation process and an unrivalled final product that is completed with professionalism and passion.',
		},
		{
			title: 'Takedown and Storage',
			copy:
				'When Winter is over, we carefully disassemble and store your rink so that it’s ready to be enjoyed again and again, year after year!',
		},
	],
	testimonial: {
		heading: 'WE WORK HARD TO MAKE SURE ALL OF OUR CUSTOMERS BECOME FANS.',
		copy:
			'My son Trevor would play on the rink for 3 hours at a time. The best part for him is being able to just walk down with his stick and start playing with his friends. My kid loves it and practically lives on it, thank you First Line Rinks!',
		name: '— Melanie Jardine, Belleville Ontario',
	},
};

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<SEO title="Home" />
			<HomeTemplate copy={copy} galleryData={data.projects.edges[0].node} />
		</Layout>
	);
};

export default IndexPage;
