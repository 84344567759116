import React from 'react';
import styled from 'styled-components';

const StyledBar = styled.div`
	position: relative;
	img {
		position: absolute;
		z-index: 20;
		left: ${(p) => p.left};
		right: ${(p) => p.right};
		top: ${(p) => p.top};
		width: ${(p) => p.barWidth};
		height: ${(p) => p.barHeight};
	}
	@media all and (min-width: 1600px) {
		img {
			width: calc(${(p) => p.barWidth} * 1.5);
			height: calc(${(p) => p.barHeight} * 1.5);
			top: calc(${(p) => p.top} * 1.5);
		}
	}
	@media all and (max-width: 850px) {
		&.homeBlue {
			top: calc(-16px * 18);
			img {
				max-width: 50%;
			}
		}
	}
	@media all and (max-width: 668px) {
		&.homeBlue {
			top: calc(-16px * 18);
		}
	}
	@media all and (max-width: 550px) {
		&.homeBlue {
			top: calc(-16px * 16 + 3px);
		}
	}
	@media all and (max-width: 480px) {
		&.homeBlue {
			top: calc(-16px * 14);
		}
	}
	@media all and (max-width: 400px) {
		&.homeBlue {
			top: calc(-16px * 12 + 7px);
		}
	}
	@media all and (max-width: 350px) {
		&.homeBlue {
			top: calc(-16px * 9.5);
		}
	}
`;

const Bar = ({ className, color, left, right, top, width, height }) => {
	let img;
	if (color === 'red') {
		img = '/img/FLR_RedBar.png';
	} else if (color === 'blue') {
		img = '/img/General_blue_bar.svg';
	}

	return (
		<StyledBar
			left={left}
			right={right}
			top={top}
			barWidth={width}
			barHeight={height}
			className={className}
		>
			<img src={img} alt="bar" />
		</StyledBar>
	);
};

export default Bar;
