import React from 'react';

import HomeMasthead from '../components/home/masthead';
import Lake from '../components/home/lake';
import ProjectContent from '../components/home/project-content';
import ProjectSlider from '../components/project-slider.js';
import Process from '../components/home/process';
import Testimonial from '../components/home/testimonial';
import Bar from '../components/edgebar.js';

const HomeTemplate = ({ galleryData, copy }) => {
	return (
		<>
			<HomeMasthead data={copy} />
			<Bar
				color="red"
				left="0"
				right="auto"
				top="-250px"
				width="auto"
				height="500px"
			/>
			<Lake data={copy} />
			<Bar
				className="homeBlue"
				color="blue"
				left="auto"
				right="-5px"
				top="-25px"
				width="300px"
				height="auto"
			/>

			<ProjectContent
				title={galleryData.title}
				excerpt={galleryData.gallery.excerpt}
				slug={galleryData.fields.slug}
				heading="FEATURED PROJECT"
			>
				<ProjectSlider images={galleryData.images} />
			</ProjectContent>

			<Process data={copy} />
			<Bar
				color="red"
				left="auto"
				right="0"
				top="-300px"
				width="auto"
				height="500px"
			/>
			<Testimonial data={copy} />
		</>
	);
};

export default HomeTemplate;
