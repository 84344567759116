import React from 'react';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
	padding: 3rem 0;
	width: 70%;
	max-width: 850px;
	margin: 0 auto;
	text-align: center;
	h2 {
		color: ${(p) => p.theme.color.red};
		width: 60%;
		min-width: 345px;
		margin: 0 auto;
	}
	.testimonial {
		font-style: italic;
		margin: 2.5rem 0 2rem;
	}
	.name {
		font-size: 0.8rem;
	}
	@media all and (min-width: 1600px) {
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		p {
			font-size: 1.3rem;
			line-height: 1.5rem;
			&.name {
				font-size: 1.1rem;
			}
		}
	}
	@media all and (max-width: 768px) {
		width: 100%;
		padding: 3rem 4rem;
		h2 {
			min-width: auto;
		}
	}
	@media all and (max-width: 668px) {
		padding: 3rem;
		h2 {
			width: 100%;
		}
	}
`;

const Testimonial = ({ data }) => {
	return (
		<StyledTestimonial>
			<h2>{data.testimonial.heading}</h2>
			<p className="testimonial">{data.testimonial.copy}</p>
			<p className="name">{data.testimonial.name}</p>
		</StyledTestimonial>
	);
};

export default Testimonial;
