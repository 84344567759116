import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledHomeProject = styled.div`
	padding: 4rem 5rem;
	h2 {
		color: ${(p) => p.theme.color.red};
		margin-bottom: 1.5rem;
	}
	.content-cols {
		display: flex;
		.left {
			width: 30%;
			display: flex;
			align-items: center;
			padding-right: 4rem;
			.text-container {
				a.title {
					font-weight: 700;
					font-size: 1.5rem;
					text-transform: uppercase;
					transition: 0.5s;
					&:hover {
						color: #57595b;
					}
				}
				p {
					margin: 1rem 0 2rem;
				}
			}
		}
		.right {
			width: 70%;
			margin-left: auto;
			padding: 0 2rem;
		}
	}
	@media all and (min-width: 1200px) {
		padding: 4rem 8rem;
		.content-cols {
			.left {
				width: 35%;
			}
			.right {
				width: 60%;
			}
		}
	}
	@media all and (min-width: 1400px) {
		padding: 6rem 15rem;
		.content-cols {
			.left {
				width: 40%;
			}
			.right {
				width: 55%;
			}
		}
	}
	@media all and (min-width: 1600px) {
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		p,
		a {
			font-size: 1.3rem !important;
			line-height: 1.5rem;
		}
		a.title {
			font-size: 1.6rem !important;
			line-height: 2rem;
		}

		padding: 6rem 20rem;
		.content-cols {
			.left {
				.text-container {
					max-width: 400px;
					margin-left: auto;
				}
			}
			.right {
				max-width: 600px;
				padding: 0;
				margin-right: 6rem;
			}
		}
	}
	@media all and (max-width: 1000px) {
		.content-cols {
			.left {
				width: 45%;
				padding-right: 3rem;
			}
			.right {
				width: 55%;
			}
		}
	}
	@media all and (max-width: 850px) {
		padding: 3rem 4rem 4rem;
		.content-cols {
			.left {
				width: 40%;
				padding-right: 1rem;
			}
			.right {
				width: 60%;
			}
		}
	}
	@media all and (max-width: 668px) {
		padding: 3rem 6rem 4rem;
		.content-cols {
			flex-direction: column;
			.left {
				width: 100%;
			}
			.right {
				width: 100%;
				padding: 0;
				margin-top: 3rem;
			}
		}
	}
	@media all and (max-width: 550px) {
		padding: 3rem 4rem 4rem;
	}
	@media all and (max-width: 400px) {
		padding: 3rem;
	}
`;

const HomeProjectContent = ({ title, heading, excerpt, slug, children }) => (
	<StyledHomeProject>
		<div className="content-cols">
			<div className="left">
				<div className="text-container">
					<h2>{heading}</h2>
					<Link className="title" to={slug}>
						{title}
					</Link>
					<p>{excerpt}</p>
					<Link className="redButton" to="/projects">
						VIEW ALL
					</Link>
				</div>
			</div>
			<div className="right">{children}</div>
		</div>
	</StyledHomeProject>
);

export default HomeProjectContent;
